import React, { useContext, useEffect, useState } from "react";
import LanguageContext from "../../context/language-context";
import ThemeContext from "../../context/theme-context";
import { FaTrashAlt } from "react-icons/fa";

export default function OneCompiler({ handleChildContent, localStorageName }) {
  console.log(localStorageName);
  const { language, setLanguage } = useContext(LanguageContext);

  const theme = useContext(ThemeContext);
  const [content, setContent] = useState(() => {
    let localStorageItem = JSON.parse(localStorage.getItem(localStorageName));
    console.log(localStorageItem);
    if (localStorageItem) {
      return localStorageItem;
    } else {
      return "";
    }
  });

  useEffect(() => {
    if (document.getElementById("oc-editor")) {
      const iFrame = document.getElementById("oc-editor"); // add an ID for the <iframe tag
      var localFilename = "main.py";
      var localContent = content;
      const onLoad = () => {
        iFrame.contentWindow.postMessage(
          {
            eventType: "populateCode",
            language: "python",
            files: [
              {
                name: localFilename,
                content: localContent,
              },
            ],
          },
          "*"
        );
      };
      iFrame.addEventListener("load", onLoad);
      return () => {
        iFrame.removeEventListener("load", onLoad);
      };
    }
  }, []);

  // get the content from inside the OneCompiler
  useEffect(() => {
    window.onmessage = function (e) {
      if (e.data && e.data.language) {
        const files = e.data.files;
        const length = files.length;
        for (let i = 0; i < length; i++) {
          console.log(files[i]);
          handleChildContent(files[i].content);
        }
      }
    };
  }, []);

  // clear the text of the OneCompiler
  // delete the item in local storage
  const handleClear = (event) => {
    event.preventDefault();
    const confirmed1 = window.confirm("Are you sure you want to clear?");
    if (confirmed1) {
      const confirmed2 = window.confirm(
        "Again Confirm? Press 'Cancel' to cancel, Press 'OK' to clear."
      ); // swap "OK" and "Cancel"
      if (confirmed2) {
        localStorage.removeItem(localStorageName);
        // // pass a specific string to the parent component which will remove the question
        // handleChildContent("!d(l@t)!");
        const iFrame = document.getElementById("oc-editor"); // add an ID for the <iframe tag
        handleChildContent("!d(l@t)!");
        localStorage.removeItem(localStorageName);
        iFrame.contentWindow.postMessage(
          {
            eventType: "populateCode",
            language: "python",
            files: [
              {
                name: "main.py",
                content: "",
              },
            ],
          },
          "*"
        );
      }
    }
  };

  const url = `https://onecompiler.com/embed/python?codeChangeEvent=true&theme=${theme.theme}&hideNewFileOption=true&hideNew=true&hideTitle=true&listenToEvents=true`;
  return (
    <div className="explanation-container">
      <div className="w-full my-2 flex justify-end p-2">
        <button className={`button {theme}`} onClick={handleClear}>
          <FaTrashAlt />
        </button>
      </div>
      <iframe
        id="oc-editor"
        title="OneCompiler"
        height="800px"
        src={url}
        width="100%"
      />
    </div>
  );
}
