import MainLayout from "../layouts/main-layout";
export default function Articles() {
  /**
   * Component to include all the articles here.
   */
  return (
    <MainLayout >
      <a rel="noreferrer" href="https://www.google.com" target="_blank">
        Google
      </a>
      <a rel="noreferrer" href="https://www.facebook.com" target="_blank">
        Facebook
      </a>
      <a rel="noreferrer" href="https://www.youtube.com" target="_blank">
        YouTube
      </a>
    </MainLayout>
  );
}
