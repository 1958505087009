// using jszip and file-saver library to generate the JSON zip file
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Button from "../button";

export default function RenderGenerateJSONzipButton({ item }) {
  //   const langResources = resources[language];
  function generateJSONzip() {
    // Create a new zip object
    const zip = new JSZip();

    // Encrypting the data
    console.log("Parameter: " + item);
    console.log(item);
    let localData = JSON.stringify(item);

    // Add the JSON data to the zip file with a filename of "data.json"
    zip.file("data.json", JSON.stringify(localData));

    // Generate the zip file
    zip.generateAsync({ type: "blob" }).then(function (blob) {
      // Save the zip file using the FileSaver package
      saveAs(blob, "data.zip");
    });
  }
  return <Button onClick={generateJSONzip}>Download JSON answer</Button>;
}
