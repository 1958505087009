import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export function DropDownItem({ buttonText, promptText, target }) {
  return <>{/* <button onClick={handleButtonClick}>{buttonText}</button> */}</>;
}

function Button(props) {
  const [isOpen, toggleOpen] = useState(0);
  const navigate = useNavigate();
  const toggleMenu = () => {
    toggleOpen((prev) => !prev);
  };
  const handleButtonClick = function (promptText, target) {
    if (promptText) {
      const acceptTerms = window.confirm(promptText);
      if (acceptTerms) {
        navigate(target);
      }
    } else {
      navigate(target);
    }
  };
  const dropdownStyle =
    "block px-4 py-4 hover:bg-secondary hover:text-primary  dark:text-primary dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer";
  return (
    <div className="relative">
      <div className="flex">
        <button
          className="bg-foreground py-4 px-10 
      rounded-l-full text-white font-xl 
      hover:bg-secondary hover:text-primary
      font-bold
      dark:bg-secondary dark:text-black
      dark:hover:bg-primary dark:hover:text-white
      "
          onClick={() => {
            handleButtonClick(props.promptText, props.target);
          }}
        >
          {props.name}
        </button>
        <div
          className="bg-foreground py-4 px-4
      rounded-r-full text-white font-xl 
      hover:bg-secondary hover:text-primary
      font-bold
      dark:bg-secondary dark:text-black
      dark:hover:bg-primary dark:hover:text-white
      cursor-pointer
      "
          onClick={toggleMenu}
        >
          <i className="fas fa-caret-down"></i>
        </div>
      </div>

      <ul
        className={
          `${isOpen ? "block" : "hidden"}` +
          " bg-foreground rounded-2xl w-full top-16 absolute text-accent  dark:bg-secondary overflow-hidden"
        }
        aria-labelledby="dropdownDefaultButton"
      >
        {React.Children.toArray(props.children).map((child) => (
          <li
            className={dropdownStyle}
            onClick={() => {
              handleButtonClick(child.props.promptText, child.props.target);
            }}
          >
            {child.props.buttonText}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Button;
