import MainLayout from "../layouts/main-layout";
export default function SampleReports() {
  /**
   * Component to include all the articles here.
   */
  return (
    <MainLayout>
      <a
        rel="noreferrer"
        href="https://processfeedback.pages.dev/writingreport/a8b5204ef63"
        target="_blank"
      >
        Sample report 1
      </a>
    </MainLayout>
  );
}
