import React from "react";
import styled from "styled-components";
import { Draggable } from "@hello-pangea/dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 4px;
  background-color: ${(props) =>
    props.isDragging ? "gray" : "var(--primary-color)"};
  display: flex;
  color: var(--secondary-color);
`;

const Handle = styled.div`
  width: 20px;
  height: 20px;
  ${"" /* background-color: orange; */}
  border-radius: 4px;
  margin-right: 8px;
`;

const ReportItem = ({ reportItem, index }) => {
  return (
    <Draggable draggableId={reportItem.id} index={index}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <Handle {...provided.dragHandleProps}>
            <FontAwesomeIcon icon={faGripVertical} />
          </Handle>
          {reportItem.content}
        </Container>
      )}
    </Draggable>
  );
};

export default ReportItem;
