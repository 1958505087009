import React from "react";

import { useRef, useContext, useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import ThemeContext from "../../context/theme-context";
import { defineTheme } from "../../features/judge-0/lib/defineTheme";

// sanitize the HTML string via dompurify
import DOMPurify from "dompurify";

function RenderReportMoncao({
  content,
  question,
  trimmedCount,
  endOfWordMarker,
  endOfSentenceMarkerFirst,
  endOfSentenceMarkerSecond,
}) {
  console.log("Render Report Content: " + content);
  console.log(question);
  console.log(content);

  const contentSeperated = content;
  const editorRef = useRef(null);
  const { theme, setTheme } = useContext(ThemeContext);
  const [monaco_theme, setJudgeTheme] = useState("chrome-devtools");

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
    editor.onDidFocusEditorText(() => {
      editor.trigger("anyString", "editor.action.selectAll");
    });
  };

  // useeffect to handle the theme of the editor
  useEffect(() => {
    if (theme === "light") {
      defineTheme("chrome-devtools").then((_) =>
        setJudgeTheme({ value: "chrome-devtools", label: "Chrome Dev Tools" })
      );
    }
    if (theme === "dark") {
      defineTheme("brilliance-black").then((_) =>
        setJudgeTheme({ value: "brilliance-black", label: "Brilliance Black" })
      );
    }
  }, [theme]);

  return (
    <>
      <div className="" style={{ margin: "10px", height: "200px" }}>
        <h3 className="explanation-heading">
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }}
          />
        </h3>
        <Editor
          value={contentSeperated}
          language="javascript"
          options={{
            readOnly: true,
            wordWrap: "on",
            automaticLayout: true,
            lineNumbers: "on",
          }}
          editorDidMount={handleEditorDidMount}
          height="100%"
          theme={monaco_theme.value}
        />
      </div>
    </>
  );
}

export default RenderReportMoncao;
