import React, { useState } from "react";
import MainLayout from "../layouts/main-layout";

const FAQS = [
  {
    question: "This is a test",
    answer: "This is a test",
  },
  {
    question: "This is a test",
    answer: "This is a test",
  },
  {
    question: "This is a test",
    answer: "This is a test",
  },
  {
    question: "This is a test",
    answer: "This is a test",
  },
];

export default function FaqPage() {
  const [open, setOpen] = useState(null);
  return (
    <MainLayout>
      <section className="relative overflow-hidden">
        <img
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
          src="flaro-assets/images/faqs/gradient.svg"
          alt=""
        />
        <div className="relative z-10 px-4 mx-auto">
          <div className="md:max-w-4xl mx-auto">
            <h2 className="font-bold text-3xl text-primary mt-8 dark:text-white">
              Frequently Asked Questions
            </h2>
            <div className="mb-11 flex flex-wrap mt-8">
              {FAQS.map((e, i) => (
                <div className="w-full p-1">
                  <a
                    href="##"
                    onClick={() => {
                      if (open === i) {
                        setOpen(null);
                        return;
                      }
                      setOpen(i);
                    }}
                  >
                    <div className="py-7 px-8 bg-white dark:bg-primaryDark border-2 border-secondary rounded-2xl shadow-10xl">
                      <div className="flex flex-wrap justify-between -m-2">
                        <div className="flex-1 p-2">
                          <h3 className="mb-4 text-lg font-semibold leading-normal">
                            {e.question}
                          </h3>
                          <p
                            className={
                              `${open === i ? "block" : "hidden"}` +
                              " dark:text-white"
                            }
                          >
                            {e.answer}
                          </p>
                        </div>
                        <div className="w-auto p-2">
                          {open !== i ? (
                            <svg
                              class="relative top-1"
                              width="18"
                              height="18"
                              viewbox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.25 6.75L9 12L3.75 6.75"
                                stroke="var(--pf-secondary-color)"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              className="relative top-1"
                              width="20"
                              height="20"
                              viewbox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.16732 12.5L10.0007 6.66667L15.834 12.5"
                                stroke="var(--pf-secondary-color)"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
