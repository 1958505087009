import React, { useEffect } from "react";

export function InputNumber(props) {
  return <input className="bg-secondary dark:bg-primary" {...props}></input>;
}

export default function Input(props) {
  useEffect(() => {
    console.log(props.onChange);
  });
  return <input className="bg-secondary dark:bg-primary" {...props}></input>;
}
