/**
 * npm install @monaco-editor/react
 * npm install monaco-themes
 * npm i react-select
 * npm i react-toastify
 * npm i axios
 */
import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import LanguageContext from "../../context/language-context";
import ThemeContext from "../../context/theme-context";
import CodeEditorWindow from "./components/CodeEditorWindow";
import { defineTheme } from "./lib/defineTheme";
import useKeyPress from "./hooks/useKeyPress";
import Footer from "./components/Footer";
import OutputWindow from "./components/OutputWindow";
import CustomInput from "./components/CustomInput";
import OutputDetails from "./components/OutputDetails";
// import ThemeDropdown from "./components/ThemeDropdown";
import { languageOptions } from "./constants/languageOptions";
import LanguagesDropdown from "./components/LanguagesDropdown";
import "../../assets/styles/judge0.css";
import { FaTrashAlt } from "react-icons/fa";

export default function JudgeZero({ handleChildContent, localStorageName }) {
  const { language, setLanguage } = useContext(LanguageContext);
  const { theme, setTheme } = useContext(ThemeContext);
  const [code, setCode] = useState(() => {
    let localStorageCode = JSON.parse(localStorage.getItem(localStorageName));
    console.log(localStorageCode);
    if (localStorageCode) {
      return localStorageCode;
    } else {
      return "";
    }
  });
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [judge_theme, setJudgeTheme] = useState("chrome-devtools");
  const [programming_language, setProgrammingLanguage] = useState(
    languageOptions[0]
  );
  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");
  const editorClassTheme = theme === "dark" ? "dark-mode" : "light-mode";
  // this is for wrapping editor to avoid resize observer limit
  const ref = useRef();
  const [height, setHeight] = useState(null);
  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      setHeight(entry.contentRect.height);
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);
  // useeffect to handle the theme of the editor
  useEffect(() => {
    if (theme === "light") {
      defineTheme("chrome-devtools").then((_) =>
        setJudgeTheme({ value: "chrome-devtools", label: "Chrome Dev Tools" })
      );
    }
    if (theme === "dark") {
      defineTheme("brilliance-black").then((_) =>
        setJudgeTheme({ value: "brilliance-black", label: "Brilliance Black" })
      );
    }
  }, [theme]);
  // function to handle the change of the dropdown theme in 
  // function handleThemeChange(th) {
  //   const theme = th;
  //   console.log("theme...", theme);

  //   if (["light", "vs-dark"].includes(theme.value)) {
  //     setJudgeTheme(theme);
  //   } else {
  //     defineTheme(theme.value).then((_) => setJudgeTheme(theme));
  //   }
  // }
  // to determine the language selected
  const onSelectChange = (sl) => {
    console.log("selected Option...", sl);
    setProgrammingLanguage(sl);
  };
  // to handle the compile procedure
  const handleCompile = () => {
    setProcessing(true);
    const formData = {
      language_id: programming_language.id,
      // encode source code in base64
      source_code: window.btoa(code),
      stdin: window.btoa(customInput),
    };
    const options = {
      method: "POST",
      url: "https://judge0-ce.p.rapidapi.com/submissions",
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
      data: formData,
    };
    axios
      .request(options)
      .then(function (response) {
        console.log("res.data", response.data);
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        console.log("Error Occured: " + err);
        let error = err.response ? err.response.data : err;
        // get error status
        let status = err.response.status;
        console.log("status", status);
        if (status === 429) {
          console.log("too many requests", status);
        }
        setProcessing(false);
        console.log("catch block...", error);
      });
  };
  // handling enter press and ctrl press
  useEffect(() => {
    if (enterPress && ctrlPress) {
      console.log("enterPress", enterPress);
      console.log("ctrlPress", ctrlPress);
      handleCompile();
    }
  }, [ctrlPress, enterPress]);

  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        handleChildContent(data);
        // if (!(data === "")) {
        //   localStorage.setItem(
        //     "Code_data",
        //     JSON.stringify({
        //       language: language,
        //       file_and_content: { name: "main.py", content: data },
        //     })
        //   );
        // }
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_RAPID_API_URL + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        setOutputDetails(response.data);
        console.log("response.data", response.data);
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
    }
  };

  // clear the text of the JudgeZero
  // delete the item in local storage
  const handleClear = (event) => {
    event.preventDefault();
    const confirmed1 = window.confirm("Are you sure you want to clear?");
    if (confirmed1) {
      const confirmed2 = window.confirm(
        "Again Confirm? Press 'Cancel' to cancel, Press 'OK' to clear."
      ); // swap "OK" and "Cancel"
      if (confirmed2) {
        localStorage.removeItem(localStorageName);
        // // pass a specific string to the parent component which will remove the question
        // handleChildContent("!d(l@t)!");
        const iFrame = document.getElementById("oc-editor"); // add an ID for the <iframe tag
        handleChildContent("!d(l@t)!");
        localStorage.removeItem(localStorageName);
        setCode("");
      }
    }
  };

  return (
    <div className={editorClassTheme}>
      <div className="delete-button">
        <button className={`button {theme}`} onClick={handleClear}>
          <FaTrashAlt />
        </button>
      </div>
      <div ref={ref} style={{ height: `${height}px` }}>
        <div className="judge-0-window">
          <div className="monaco-editor-window">
            <CodeEditorWindow
              code={code}
              onChange={onChange}
              language={programming_language.value}
              theme={judge_theme.value}
            />
          </div>
          <div className="utility-window">
            <button
              className="button compile-button"
              onClick={handleCompile}
              disabled={!code}
            >
              {processing ? "Processing" : "Run"}
            </button>
            <div className="language-dropdown">
              <LanguagesDropdown onSelectChange={onSelectChange} />
            </div>

            {/* <div className="theme-dropdown">
          <ThemeDropdown
            handleThemeChange={handleThemeChange}
            theme={theme}
          />
        </div> */}
            <div className="output-custom-input">
              <h3 className="output-text">Output</h3>
              <div className="output-window">
                <OutputWindow outputDetails={outputDetails} />
              </div>
              <div>
                {outputDetails && <OutputDetails outputDetails={outputDetails} />}
              </div>
              <div className="custom-input-window">
                <CustomInput
                  customInput={customInput}
                  setCustomInput={setCustomInput}
                />
              </div>
              
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
