import React from "react";
import styled from "styled-components";
import { Droppable } from "@hello-pangea/dnd";
import ReportItem from "./reportItem";

const Container = styled.div`
  margin: 0px;
  border: 0px solid lightgrey;
  border-radius: 2px;
`;
const Title = styled.h3`
  padding: 0px;
`;
const ReportItemList = styled.div`
  padding: 4px;
  transition: background-color 0.2s ease;
  background-color: ${(props) =>
    props.isDraggingOver
      ? "var(--drag-drop-primary-color)"
      : "var(--drag-drop-secondary-color)"};
`;

const Column = ({ column, reportItems }) => {
  return (
    <Container>
      <Title>{column.title}</Title>
      <Droppable droppableId={column.id}>
        {(provided, snapshot) => (
          <ReportItemList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {reportItems.map((reportItem, index) => (
              <ReportItem
                key={reportItem.id}
                reportItem={reportItem}
                index={index}
              />
            ))}
            {provided.placeholder}
          </ReportItemList>
        )}
      </Droppable>
    </Container>
  );
};

export default Column;
