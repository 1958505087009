import React, { useState, useContext } from "react";

// sanitize the HTML string via dompurify
import DOMPurify from "dompurify";

import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

import MainLayout from "../layouts/main-layout";
import CkEditorCore from "../features/ck-editor/main";
import ViewReport from "../components/view-report-button/view-report-button";
import fetchData from "../utils/get-from-database";

import language_resorces from "../data/translation-language";
import LanguageContext from "../context/language-context";

export default function RenderWriting() {
  const { language, setLanguage } = useContext(LanguageContext);
  const langResources = language_resorces[language];

  const [question, setQuestion] = useState(() => {
    let localStorageQuestion = JSON.parse(
      localStorage.getItem("Writing_Report_Question")
    );
    if (localStorageQuestion) {
      return localStorageQuestion.question;
    } else {
      return "";
    }
  });
  const [ckeditorContent, setCkeditorContent] = useState("");
  const [fetchButtonClicked, setFetchButtonClicked] = useState(false);
  const [fetchQuestion, setFetchQuestion] = useState(false);
  //getting the id and language from the provided URL
  const { id } = useParams();

  // handles the fetch form database function
  async function handleFetchEssay() {
    setFetchButtonClicked(true);
    if (id) {
      let data = await fetchData(id);
      setFetchQuestion(true);
      let item = data.replace(/<h1>.*<\/h1>/g, "");
      setQuestion(item);
      let question_data = {
        quuid: id,
        question: item,
      };
      if (localStorage.getItem("Writing_Report_Question")) {
        const existingData = JSON.parse(
          localStorage.getItem("Writing_Report_Question")
        );
        if (existingData.quuid !== id) {
          notifyError("Replacing existing question!");
        }
      }
      localStorage.setItem(
        "Writing_Report_Question",
        JSON.stringify(question_data)
      );
    }
  }

  // gets the content written in the CKeditor
  const handleChildContent = (value) => {
    // if the value is a specific string delete the content of the question
    if (value == "!d(l@t)!") {
      localStorage.removeItem("Writing_Report_Question");
      setQuestion("");
    }
    setCkeditorContent(value);
    let item = value.replace(/<h1>.*<\/h1>/g, "");
    localStorage.setItem("ReportAnswer", JSON.stringify(item));
  };

  const notifyError = (message) =>
    toast(<p style={{ fontSize: 16 }}>{message}</p>, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: "error",
    });

  return (
    <MainLayout>
      {id ? (
        <>
          {fetchQuestion ? (
            <>
              {question ? (
                <>
                  <h2 className="dark:text-white">
                    {langResources.writing_page_question}:
                  </h2>
                  <div
                    className="dark:text-white"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(question),
                    }}
                  ></div>
                </>
              ) : (
                <></>
              )}
              <>
                <div style={{ width: "100%" }}>
                  <CkEditorCore
                    handleChildContent={handleChildContent}
                    localStorageName={"ReportAnswer"}
                  />
                </div>
                <div className="save-options">
                  <div className="save-buttons">
                    <p align="center">
                      <ViewReport target={"/writingreport"} />
                    </p>
                  </div>
                </div>
              </>
            </>
          ) : (
            <>
              {fetchButtonClicked ? (
                <>{langResources.writing_page_fetchingFromDatabase}</>
              ) : (
                <button onClick={handleFetchEssay}>
                  {langResources.writing_page_fetchQuestionButton}
                </button>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <>
            {question ? (
              <>
                <h2 className="dark:text-white">Question:</h2>
                <div
                  className="dark:text-white"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(question),
                  }}
                ></div>
              </>
            ) : (
              <></>
            )}
            <div style={{ padding: "3rem" }}>
              <CkEditorCore
                handleChildContent={handleChildContent}
                localStorageName="ReportAnswer"
              />
            </div>
            <div className="w-full flex justify-center">
              <ViewReport target={"/writingreport"} />
            </div>
          </>
        </>
      )}
      <ToastContainer />
    </MainLayout>
  );
}
