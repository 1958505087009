import React, { useState, useEffect } from "react";
import { DragDropContext } from "@hello-pangea/dnd";
import Column from "./column";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
//import AnimatedBarChart from "../Plots/AnimatedBarChart";

function DragNDrop({ data }) {
  const [state, setState] = useState(null);

  useEffect(() => {
    // Set initialData state only when data prop is available
    if (data) {
      const initialData = {
        reportItems: {
          "reportItem-1": {
            id: "reportItem-1",
            content: (
              <CKEditor
                editor={CustomEditor}
                data={data}
                onBlur={console.log("")}
                onReady={(editor) => {
                  editor.enableReadOnlyMode("editor");
                }}
                config={{ isReadOnly: true }}
              />
            ),
          },
          // "reportItem-2": {
          //   id: "reportItem-2",
          //   content: <AnimatedBarChart />,
          // },
          "reportItem-3": {
            id: "reportItem-3",
            content: "Comparison with the master answer (Todo):",
          },
          "reportItem-4": {
            id: "reportItem-4",
            content: "Comparison with the baseline answer (Todo): ",
          },
        },
        columns: {
          "column-1": {
            id: "column-1",
            //title: "Reports",
            reportItemIds: [
              "reportItem-1",
              // "reportItem-2",
              "reportItem-3",
              "reportItem-4",
            ],
          },
        },
        // Facilitate reordering of the columns
        columnOrder: ["column-1"],
      };

      setState(initialData);
    }
  }, [data]);

  if (!state) {
    // If state is not set yet, return a loading state or null
    return null;
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const column = state.columns[source.droppableId];
    const newReportItemIds = Array.from(column.reportItemIds);
    newReportItemIds.splice(source.index, 1);
    newReportItemIds.splice(destination.index, 0, draggableId);

    const newColumn = {
      ...column,
      reportItemIds: newReportItemIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newColumn.id]: newColumn,
      },
    };

    setState(newState);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {state.columnOrder.map((columnId) => {
        const column = state.columns[columnId];
        const reportItems = column.reportItemIds.map(
          (reportItemId) => state.reportItems[reportItemId]
        );

        return (
          <Column key={column.id} column={column} reportItems={reportItems} />
        );
      })}
    </DragDropContext>
  );
}

export default DragNDrop;
