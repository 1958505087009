// language translation for the entire app

const language_resorces = {
  en: {
    navbar_home: "Home",
    navbar_menuItemRelatedArticles: "Articles",
    navbar_menuItemStory: "Story",
    navbar_menuItemManuscript: "Manuscript",
    navbar_menuItemReports: "Sample reports",
    navbar_menuItemMedia: "Media",
    landing_page_welcomeMessage: "Welcome participants..",
    landing_page_meaningfulExplanations:
      "Your inputs will be extremely helpful to answer important questions in science",
    landing_page_startOptionOne: "Start Option One",
    landing_page_startOptionTwo: "Start Option Two",
    landing_page_startOptionThree: "Start Option Three (beta)",
    landing_page_startOptionFour: "Start Option Four (beta)",
    landing_page_startOptionFive: "Start Option Five",
    landing_page_buttonQuestionUpload: "Upload a question",
    landing_page_uploadWritingExistingAnswer: "View Text Answer",
    landing_page_uploadCodeExistingAnswer: "View Code Answer",
    landing_page_termsAndConditions:
      "Please read and accept the terms and conditions to continue.",
    landing_page_continue: "Continue",
    writing_page_question: "Question",
    writing_page_fetchingFromDatabase: "Fetching from database..",
    writing_page_fetchQuestionButton: "Fetch Question",
    onecompiler_page_question: "Question",
    onecompiler_page_fetchingFromDatabase: "Fetching from database..",
    onecompiler_page_fetchQuestionButton: "Fetch Question",
    report_page_reportGenerationParameters: "Report generation parameters",
  },
  fr: {
    navbar_home: "Maison",
    navbar_menuItemRelatedArticles: "Articles",
    navbar_menuItemStory: "Story",
    navbar_menuItemManuscript: "Manuscript",
    navbar_menuItemReports: "Reports",
    navbar_menuItemMedia: "Media",
    landing_page_welcomeMessage: "Bienvenue aux participants..",
    landing_page_meaningfulExplanations:
      "Vos contributions seront extrêmement utiles pour répondre à des questions scientifiques importantes",
    landing_page_startOptionOne: "Commencer Option One",
    landing_page_startOptionTwo: "Commencer Option Two",
    landing_page_startOptionThree: "Commencer Option Three",
    landing_page_startOptionFour: "Commencer Option Four",
    landing_page_startOptionFive: "Commencer Option Five",
    landing_page_buttonQuestionUpload: "Upload a question",
    landing_page_uploadWritingExistingAnswer:
      "Télécharger la réponse existante",
    landing_page_uploadCodeExistingAnswer:
      "Télécharger la code réponse existante",
    landing_page_termsAndConditions:
      "Veuillez lire et accepter les conditions pour continuer.",
    landing_page_continue: "Continuer",
    writing_page_question: "Question",
    writing_page_fetchingFromDatabase: "Récupération de la base de données..",
    writing_page_fetchQuestionButton: "Récupérer la question",
    onecompiler_page_question: "Question",
    onecompiler_page_fetchingFromDatabase:
      "Récupération de la base de données..",
    onecompiler_page_fetchQuestionButton: "Récupérer la question",
    report_page_reportGenerationParameters:
      "Paramètres de génération de rapport",
  },
};

export default language_resorces;
