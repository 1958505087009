import { useState, useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import SaveToDataBase from "./save-to-database";
import { uid } from "uid";
import LanguageContext from "../context/language-context";
// import resources from "../Language";
import CopyableTextContainer from "../components/copyable-URL-container/copyable-URL-container";
import Button from "../components/button";

// function to save in database and added encryption
export default function SaveToDatabaseOption({ item, encrypt, targetPage }) {
  /**
   * Performs Captcha Verifications also.
   * Calls SaveToDB after Verification is complete.
   */
  const { language, setLanguage } = useContext(LanguageContext);
  console.log(item);
  // let data = item.replace(/<h1>.*<\/h1>/g, "");
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [uuid, setUuid] = useState("");
  const [clickedSaveToDatabase, setClickedSaveToDatabase] = useState(true);

  // const langResources = resources[language];
  async function handleClickSaveToDataBase() {
    setClickedSaveToDatabase(false);
    setShowCaptcha(true);
  }

  async function handleCaptchaVerify() {
    setIsCaptchaVerified(true);
    setShowCaptcha(false);
    try {
      const newUuid = uid() + uid() + uid();
      setUuid(newUuid);
      console.log(item);
      console.log(newUuid);
      await SaveToDataBase(item, newUuid, encrypt);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {clickedSaveToDatabase && (
        <>
          <Button onClick={handleClickSaveToDataBase}>Save</Button>
        </>
      )}
      {showCaptcha && (
        <div>
          <ReCAPTCHA
            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={handleCaptchaVerify}
          />
        </div>
      )}
      {uuid && (
        <div>
          URL:
          <CopyableTextContainer
            text={`${window.location.origin}/${targetPage}/${uuid}?lang=${language}`}
          />
        </div>
      )}
    </>
  );
}
