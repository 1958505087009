import { db } from "../firebase-setup/firebase";

import { set, ref } from "firebase/database";
import Crypto from "./Crypto";

async function SaveToDataBase(item, uuid, encrypt) {
  /**
   * Encrypts and Uploads the JSON.
   */
  console.log(item);
  console.log("Inside SaveToDataBase component.");
  console.log("Save To DB item: " + item);
  console.log("UUID: " + uuid);

  var finalText = JSON.stringify(item);
  console.log("Unecnrypted Data: " + finalText);

  if (encrypt === true) {
    // Encrypts the data
    let encryptedContent = Crypto(finalText, "Password", "Dog", true);
    var encryptedItem = { encryptedData: encryptedContent };
    console.log("Encrypted Data: " + encryptedItem);
    var encryptedText = JSON.stringify(encryptedItem);
    finalText = encryptedText;
  }

  // Uploads to the Database
  console.log("Saving to DB...");
  const writeToDataBase = () => {
    set(ref(db, `/${uuid}`), {
      finalText,
      uuid,
    });
  };
  writeToDataBase();
  console.log("Save to DB successful.");
}

export default SaveToDataBase;
