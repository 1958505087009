import React, { useState, useContext } from "react";

import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

// sanitize the HTML string via dompurify
import DOMPurify from "dompurify";

import LanguageContext from "../context/language-context";
import MainLayout from "../layouts/main-layout";
import OneCompiler from "../features/one-compiler/main";
import ViewReport from "../components/view-report-button/view-report-button";
import fetchData from "../utils/get-from-database";

import language_resorces from "../data/translation-language";
import Button from "../components/button";
export default function RenderOneCompiler() {
  const { language, setLanguage } = useContext(LanguageContext);
  const langResources = language_resorces[language];

  const [question, setQuestion] = useState(() => {
    let localStorageQuestion = JSON.parse(
      localStorage.getItem("Writing_Program_Question")
    );
    if (localStorageQuestion) {
      console.log(localStorageQuestion);
      console.log(localStorageQuestion.question);
      return localStorageQuestion.question;
    } else {
      return "";
    }
  });
  const [oneCompilerContent, setOneCompilerContent] = useState("");
  const [fetchButtonClicked, setFetchButtonClicked] = useState(false);
  const [fetchQuestion, setFetchQuestion] = useState(false);
  //getting the id and language from the provided URL
  const { id } = useParams();

  // handles the fetch form database function
  async function handleFetchEssay() {
    setFetchButtonClicked(true);
    if (id) {
      let data = await fetchData(id);
      setFetchQuestion(true);
      let item = data.replace(/<h1>.*<\/h1>/g, "");
      setQuestion(item);
      let question_data = {
        quuid: id,
        question: item,
      };
      if (localStorage.getItem("Writing_Program_Question")) {
        const existingData = JSON.parse(
          localStorage.getItem("Writing_Program_Question")
        );
        console.log(existingData);
        if (existingData.quuid !== id) {
          notifyError("Replacing existing question!");
        }
      }
      localStorage.setItem(
        "Writing_Program_Question",
        JSON.stringify(question_data)
      );
    }
  }
  // gets the content written in the OneCompiler
  const handleChildContent = (value) => {
    // if the value is a specific string delete the content of the question
    if (value == "!d(l@t)!") {
      localStorage.removeItem("Writing_Program_Question");
      setQuestion("");
    }
    setOneCompilerContent(value);
    localStorage.setItem("CodeAnswer", JSON.stringify(value));
  };

  const notifyError = (message) =>
    toast(<p style={{ fontSize: 16 }}>{message}</p>, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: "error",
    });

  return (
    <MainLayout>
      {id ? (
        <>
          {fetchQuestion ? (
            <>
              {question ? (
                <>
                  <h2>Question:</h2>
                  <div
                    className="question-container"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(question),
                    }}
                  ></div>
                </>
              ) : (
                <></>
              )}
              <>
                <div style={{ width: "100%" }}>
                  <OneCompiler
                    handleChildContent={handleChildContent}
                    localStorageName={"CodeAnswer"}
                  />
                </div>
                <div className="save-options">
                  <div className="save-buttons">
                    <p align="center">
                      <ViewReport target={"/codereport"} />
                    </p>
                  </div>
                </div>
              </>
            </>
          ) : (
            <>
              {fetchButtonClicked ? (
                <>Fetching from database..</>
              ) : (
                <Button onClick={handleFetchEssay}>Fetch Question</Button>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <>
            {question ? (
              <>
                <h2>Question:</h2>
                <div
                  className="question-container"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(question),
                  }}
                ></div>
              </>
            ) : (
              <></>
            )}
            <div style={{ padding: "3rem" }}>
              <OneCompiler
                handleChildContent={handleChildContent}
                localStorageName="CodeAnswer"
              />
            </div>
            <div className="w-full flex justify-center">
              <ViewReport target={"/codereport"} />
            </div>
          </>
        </>
      )}
      <ToastContainer />
    </MainLayout>
  );
}
