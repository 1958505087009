import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </>
);

// // for non-strict mode switch to the following
// import React from "react";
// import ReactDOM from "react-dom";
// import Root from "./App";
// ReactDOM.render(<Root />, document.getElementById("root"));
