import { htmlToText } from "html-to-text";

function HtmlTextToHtmlParagraphs(htmlText) {
  /**
   * Takes html text as input.
   * Returns array of html paragraphs.
   */

  htmlText = htmlText.replace("<p>", "");
  return htmlText.split("</p>");
}

function htmlParagraphToTextSentences(
  htmlParagraph,
  endOfSentenceMarkerFirst,
  endOfSentenceMarkerSecond,
  endOfWordMarker,
  ignoreConsecutiveEOSMarker
) {
  /**
   *
   */

  // Add end of sentence marker at the closing tag.
  // Resolved issue provided below:
  // <h1>Galaxy</h1>Hello
  // [GalaxyHello]
  htmlParagraph = htmlParagraph.replace(/(<\/h\d>)/gim, endOfWordMarker);
  htmlParagraph = htmlParagraph.replace(/(<h\d>)/gim, "");
  const textParagraph = htmlToText(htmlParagraph, {
    wordwrap: 10000,
    ignoreImage: false,
  });
  // const textParagraph = htmlParagraph;

  // Splt text to sentences when it has endOfSentenceMarker or new line... Ignores empty array element.
  const sentences = [];
  let start = 0;
  for (let i = 0; i < textParagraph.length; i++) {
    if (
      textParagraph[i] === endOfSentenceMarkerFirst ||
      textParagraph[i] === endOfSentenceMarkerSecond ||
      textParagraph[i] === "\n"
    ) {
      if (ignoreConsecutiveEOSMarker) {
        if (i + 1 < textParagraph.length) {
          if (
            textParagraph[i + 1] === endOfSentenceMarkerFirst ||
            textParagraph[i + 1] === endOfSentenceMarkerSecond ||
            textParagraph[i + 1] === "\n"
          ) {
            continue;
          }
        }
      }
      const sentence = textParagraph.slice(start, i + 1).trim();

      if (sentence) {
        sentences.push(sentence);
      }
      start = i + 1;
    }
  }
  const lastSentence = textParagraph.slice(start).trim();
  if (lastSentence) {
    sentences.push(lastSentence);
  }

  // Displaying each line to check the sentences array
  for (let i = 0; i < sentences.length; i++) {
    // console.log(i + ">>>" + sentences[i]);
  }

  return sentences;
}

function SentenceToWords(sentence, endOfWordMarker) {
  return sentence.split(endOfWordMarker);
}

function TextParagraphsToFormattedHtml(textParagraphs) {
  var formattedHtml = "";
  for (let i = 0; i < textParagraphs.length; i++) {
    for (let j = 0; j < textParagraphs[i].length; j++) {
      formattedHtml += `${j + 1}: `;
      for (let k = 0; k < textParagraphs[i][j].length; k++) {
        formattedHtml += "[" + textParagraphs[i][j][k] + "] ";
      }
      formattedHtml += "<br>";
    }
    formattedHtml += "<br>";
  }
  return "<h1></h1><p>" + formattedHtml + "</p>";
}

export default function Segmentator(
  endOfWordMarker,
  endOfSentenceMarkerFirst,
  endOfSentenceMarkerSecond,
  ignoreConsecutiveEOSMarker,
  html
) {
  /**
   * Function to segment input from CKEditor
   *
   * @param {string} endOfSentenceMarker End-of-sentence marker
   * @param {string} html HTML content of CKEditor
   * @return {string}     Final Segmented HTML.
   * */

  console.log("Inside Segmentator.");
  console.log("Untouched HTML: " + html);

  const htmlParagraphs = HtmlTextToHtmlParagraphs(html);
  console.log(htmlParagraphs);

  var textParagraphs = [];
  for (let i = 0; i < htmlParagraphs.length; i++) {
    const tempSentences = htmlParagraphToTextSentences(
      htmlParagraphs[i],
      endOfSentenceMarkerFirst,
      endOfSentenceMarkerSecond,
      endOfWordMarker,
      ignoreConsecutiveEOSMarker
    );
    var tempParagraph = [];
    for (let j = 0; j < tempSentences.length; j++) {
      tempParagraph.push(SentenceToWords(tempSentences[j], endOfWordMarker));
    }
    textParagraphs.push(tempParagraph);
  }

  const formattedHtml = TextParagraphsToFormattedHtml(textParagraphs);

  console.log("Final Segmented HTML: " + formattedHtml);

  return formattedHtml;
}
