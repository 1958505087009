import React, { useState } from "react";

import MainLayout from "../layouts/main-layout";
import CkEditorCore from "../features/ck-editor/main";
import SaveToDatabaseOption from "../utils/save-to-database-button";

import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../components/button";

export default function RenderQuestionUpload() {
  const [questionContent, setquestionContent] = useState(() => {
    if (JSON.parse(localStorage.getItem("questionUpload"))) {
      return JSON.parse(localStorage.getItem("questionUpload"));
    } else {
      return "";
    }
  });
  const [selectedFormType, setSelectedFormType] = useState("writing");

  // Handle Functions
  const handleSelectionChange = (event) => {
    setSelectedFormType(event.target.value);
    console.log(event.target.value);
  };

  // gets the content written in the CKeditor
  function handleChildContent(value) {
    setquestionContent(value);
    localStorage.setItem("questionUpload", JSON.stringify(value));
  }

  // handle the click of save bitton when their is no content
  function handleSaveWithoutContent() {
    notifyError("Please enter a question!");
  }

  const notifyError = (message) =>
    toast(<p style={{ fontSize: 16 }}>{message}</p>, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: "error",
    });

  return (
    <MainLayout>
      <h1>Question upload</h1>
      {/* Radio Button */}
      <div className="flex gap-4">
        <label>
          <input
            type="radio"
            value="writing"
            className=""
            checked={selectedFormType === "writing"}
            onChange={handleSelectionChange}
          />
          Writing
        </label>
        <label>
          <input
            type="radio"
            value="onecompiler"
            checked={selectedFormType === "onecompiler"}
            onChange={handleSelectionChange}
          />
          Programming
        </label>
      </div>
      <div className="my-4 py-10">
        <CkEditorCore
          handleChildContent={handleChildContent}
          localStorageName="questionUpload"
        />
      </div>
      <div className="w-full flex justify-center">
        {questionContent ? (
          <SaveToDatabaseOption
            item={questionContent}
            encrypt={true}
            targetPage={selectedFormType}
          />
        ) : (
          <div className="flex justify-center w-full">
            <Button onClick={handleSaveWithoutContent}>Save</Button>
          </div>
        )}
      </div>

      <ToastContainer />
    </MainLayout>
  );
}
