import React from "react";
import DragNDrop from "../drag-n-drop/DragNDrop";
import Segmentator from "./Segmentator";

// sanitize the HTML string via dompurify
import DOMPurify from "dompurify";

function RenderReport({
  content,
  question,
  trimmedCount,
  endOfWordMarker,
  endOfSentenceMarkerFirst,
  endOfSentenceMarkerSecond,
  ignoreConsecutiveEOSMarker,
}) {
  console.log(content);
  const trimmedContent = content.split(" ").splice(0, trimmedCount).join(" ");
  const contentSeperated = Segmentator(
    endOfWordMarker,
    endOfSentenceMarkerFirst,
    endOfSentenceMarkerSecond,
    ignoreConsecutiveEOSMarker,
    content
  );

  return (
    <div className="" style={{ margin: "1px" }}>
      <h3 className="explanation-heading">
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }}
        />
      </h3>
      <DragNDrop data={contentSeperated} />
    </div>
  );
}

export default RenderReport;
