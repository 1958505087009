import React, { useState } from "react";
import "../../assets/styles/textCopyAbleArea.css";

export default function CopyableTextContainer({ text }) {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 2000); // Reset copy success message after 2 seconds
      })
      .catch((error) => {
        console.error("Copy failed:", error);
      });
  };

  return (
    <div className="copyable-text-container">
      <div className="copyable-text-container__text">{text}</div>
      <button
        className="copyable-text-container__copy-button"
        onClick={handleCopyClick}
      >
        {copySuccess ? "Copied!" : "Copy"}
      </button>
    </div>
  );
}
