import MainLayout from "../layouts/main-layout";
export default function Story() {
  /**
   * Component to include all the articles here.
   */
  return (
    <MainLayout>
      <p>
        <h2>Our story (To do)</h2>I express my gratitude towards all the special
        people who have helped me in the past or are currently my source of
        energy and inspiration.
      </p>

      <p>
        In each course that I teach every semester, there are usually a few
        students who find errors in my course and provide me feedback to make
        corrections. Some others inform me about the strengths of my courses and
        help me understand the aspects that they enjoy. I am sincerely thankful
        to all the proactive and courageous students who have given me many
        informative and constructive feedbacks directly and indirectly.
      </p>

      <p>
        I am thankful to Cynthia Jobe and Christopher D. Spilling at UMSL’s
        Office of Research Administration for believing in me when I joined as
        an assistant professor right after my PhD and for guiding me through the
        process of grant writing. I took my baby steps in grant writing holding
        Cynthia’s hands and I am thankful to her for her tireless support.
      </p>

      <p>
        I am deeply thankful to my colleague Dr. Sharlee Climer for always being
        available to review my drafts. She inspires me to respect students and
        work with them as colleagues.
      </p>

      <p>
        Philip Reiss, our system administrator at UMSL, is always available to
        help me every time I need to purchase a new research server or when one
        of my servers has issues. I am extremely thankful to Philip for helping
        me with all of my servers. My students and I can access the servers 24/7
        because of his behind-the-scene efforts. Even in the middle of the
        COVID-19 outbreak, he helped me purchase a new server, install it at the
        university datacenter and reconfigure it many times.
      </p>

      <p>
        From the bottom of my heart, I would like to thank two of my high-school
        tutors, Ganesh Kumar Shrestha and Indra Timsina, who believed in me even
        when I was a poor performer during my junior and senior years in high
        school. Ganesh sir and Indra sir instilled in me the idea that the basic
        concepts in science and mathematics are fulfilling to learn when we
        simplify and understand them.
      </p>

      <p>
        In my 9th and 10th grades, my Science teachers, Kumud Adhikari and Uday
        Raj Aryal, kindled a desire in me to pursue science for the rest of my
        life. Also, during the same years, our mathematics teacher Arun Poudel
        inspired me to learn concepts in mathematics. I am very thankful for all
        the support and encouragement I received from them.
      </p>

      <p>
        Our C++ teacher Nagendra Dangal at Advanced College of Engineering in
        Nepal was my biggest source of motivation to do well in my freshman year
        in college. He repeatedly encouraged me to become an efficient
        programmer and pumped me up when I was slow. I am thankful to him for
        all the support I received from him during my academic resurgence.
      </p>

      <p>
        When my parents had come to stay with us in St. Louis in 2018, in the
        evenings we would take them to the Adult Education Literacy Center at
        the Clayton High School to learn English. Although my mom is relatively
        younger, my dad was 82 at that time and all he knew in English was to
        read the alphabet. Despite them being the poorest students in the class,
        the teacher, Ms. Laurie Lambert, never discriminated them. My parents
        would return home impressed every day, because of the indiscriminative
        teaching and the unbelievable amount of hard work that she used to put
        in to make the classes informative and exciting. I never took any
        classes with Ms. Lambert but from what my parents used to share with me,
        I think that she is one of the best teachers I have ever known. I am
        thankful to her for showing my parents what a good teacher looks like
        and how effective the education system in the US is.
      </p>

      <p>
        During the junior year of my undergraduate degree, I experienced the
        joys and challenges of working in a team when my classmates Subash
        Niroula, Susma Pant, Sharad Subedi and I teamed up for our Computer
        Science semester project. That year, I experienced the synergy of
        working in a team and also, I learnt the frustrations of dependencies
        among members. I am thankful to Subash, Susma, and Sharad for being
        there in all the ups and downs of our project phases and bearing with me
        when I may not have been as nice as they probably wanted me to be.
      </p>

      <p>
        My friends, Abhishek Dutta and Pushpeshwor Pokharel, were my role models
        during my undergraduate studies. They trusted me and inspired me to ace
        in my university courses. With Abhishek, I had my first ‘aha’ moments
        about the potential of artificial intelligence. Pushpeshwor introduced
        me to books such as the Headfirst Series, which I instantly fell in love
        with. I am grateful to my dear friends for being my role models and for
        guiding me as peers.
      </p>

      <p>
        The saying “A grandfather has silver in his hair and gold in his heart”
        applies perfectly to my father-in-law Laxman Kafle. During the final
        year of my PhD, I was extremely busy finishing three research
        manuscripts and applying to almost every open faculty position. At the
        same time, we lost our closest friend and were grieving. My
        father-in-law was with us during all of this, fully taking care of our
        child, while I finished my manuscripts, found a job that we wanted, and
        we were coping with the grief. I am deeply thankful to him for being a
        shoulder to rest on during many of our helpless times.
      </p>

      <p>
        For almost five years, as a spouse of an international PhD student in
        the US, my wife could neither work nor go to college. As much as there
        is excitement in coming to the US, the immigration policies and
        restrictions are painful for F2 VISA holders. The PhD journey felt like
        a war story for me and I was always stressed about my successful
        graduation. I will never be able to find words to thank my wife for
        being by my side unconditionally even when I was grumpy, for being my
        strength when I was not feeling great, and for arranging a party when,
        finally, it was time to celebrate my graduation.
      </p>
    </MainLayout>
  );
}
