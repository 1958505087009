import React from "react";
import { classnames } from "../utils/general";

const CustomInput = ({ customInput, setCustomInput }) => {
  return (
    <>
      {" "}
      <textarea
        style={{width: '90%', height: '80%', marginLeft: '10px'}}
        rows="5"
        cols="50"
        value={customInput}
        onChange={(e) => setCustomInput(e.target.value)}
        placeholder={`Custom input`}
        className={classnames(
          "text-area"
        )}
      ></textarea>
    </>
  );
};

export default CustomInput;
