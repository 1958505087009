import React, { useState } from "react";
import { useParams } from "react-router-dom";

import MainLayout from "../layouts/main-layout";
import RenderReportMoncao from "../components/code-report-moncao-editor/render-code-report";
import RenderReport from "../components/render-report/render-report";
import RenderGenerateJSONzipButton from "../components/JSON-zip-button/downloadJSON";
import RenderUploadJSONZipButton from "../components/JSON-zip-button/upload-JSON-zip";
import FromHeaderFields from "../components/form-header-fields/form-header-fields";
import SaveToDatabaseOption from "../utils/save-to-database-button";
import fetchData from "../utils/get-from-database";

import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../components/button";
import Input, { InputNumber } from "../components/input";

export default function RenderCodeReport() {
  const { id } = useParams();
  const [confirmView, setConfirmView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isZIPFile, setIsZIPFile] = useState(true);
  const [showUploadButton, setShowUploadButton] = useState(() => {
    if (JSON.parse(localStorage.getItem("CodeAnswer"))) {
      return false;
    } else if (id) {
      return false;
    } else {
      return true;
    }
  });

  const [endOfSentenceMarkerFirst, setEndOfSentenceMarkerFirst] = useState(".");
  const [endOfSentenceMarkerSecond, setEndOfSentenceMarkerSecond] =
    useState("");
  const [algorithmParamNgramSize, setAlgorithmParamNgramSize] = useState(2);
  const [similarityThreshold, setSimilarityThreshold] = useState(0.5);
  const [endOfWordMarker, setEndOfWordMarker] = useState(" ");
  const [ignoreConsecutiveEOSMarker, setIgnoreConsecutiveEOSMarker] =
    useState(true);
  const ngramOptions = [1, 2, 3, 4, 5];
  const similarityThresholdOptions = [0.3, 0.4, 0.5, 0.6, 0.7];
  const [showOptions, toggleShowOptions] = useState(0);

  // values for creating the report
  const [reportEndOfSentenceMarkerFirst, setReportEndOfSentenceMarkerFirst] =
    useState(".");
  const [reportEndOfSentenceMarkerSecond, setReportEndOfSentenceMarkerSecond] =
    useState("");
  const [reportAlgorithmParamNgramSize, setReportAlgorithmParamNgramSize] =
    useState(2);
  const [reportSimilarityThreshold, setReportSimilarityThreshold] =
    useState(0.5);
  const [reportEndOfWordMarker, setReportEndOfWordMarker] = useState(" ");
  const [
    reportIgnoreConsecutiveEOSMarker,
    setReportIgnoreConsecutiveEOSMarker,
  ] = useState(true);

  const [JSONData, setJSONData] = useState(() => {
    // Set JSONData to localStorage value by default
    let localWritingData = {};
    if (localStorage.getItem("CodeAnswer")) {
      toast.info("You have an active ongoing task.");
    }
    localWritingData.content = JSON.parse(localStorage.getItem("CodeAnswer"));
    console.log(localWritingData.content);
    if (localWritingData) {
      localWritingData.question = localStorage.getItem(
        "Writing_Program_Question"
      )
        ? JSON.parse(localStorage.getItem("Writing_Program_Question")).question
        : null;
    }
    console.log(localWritingData);
    return localWritingData;
  });

  // for author,title and passsword section
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [password, setPassword] = useState("");

  const handleChildAuthorChange = (value) => {
    setAuthor(value);
    localStorage.setItem("Author", value);
  };
  const handleChildTitleChange = (value) => {
    setTitle(value);
    localStorage.setItem("Title", value);
  };
  const handleChildPasswordChange = (value) => {
    setPassword(value);
  };
  // to handle the click of button when the field value are not provided
  // show toast with the appropriate error
  function handleClickViewExplanation() {
    if (author && title) {
      notifyError("Please enter password");
    } else if (author && password) {
      notifyError("Please enter title");
    } else if (title && password) {
      notifyError("Please enter author");
    } else {
      notifyError("Please fillout the necessary fields");
    }
  }

  // get data from the upload json button
  function loadUploadedJSONData(jsonString) {
    setIsZIPFile(false);
    try {
      const jsonData = JSON.parse(jsonString);
      setJSONData(jsonData);
      // setUploadStatus("success");
    } catch (error) {
      // setUploadStatus("failed");
      if (
        error instanceof SyntaxError &&
        error.message.includes("Bad control character")
      ) {
        console.log("Upload Error >>> Password error");
      } else if (
        error instanceof TypeError &&
        error.message.includes(
          "Upload Error >>> Cannot read properties of null"
        )
      ) {
        console.log("Upload Error >>> Incorrect file upload");
      } else {
        console.log("Upload Error >>> Error occurred: " + error);
      }
    }
  }

  // Handle Section
  const handlePasswordChange = (event) => {
    localStorage.setItem("encryption_passcode", event.target.value);
  };

  function handleAlgorithmParamNgramSizeSelection(event) {
    const value = event.target.value;
    setAlgorithmParamNgramSize(value);
  }

  const handleIgnoreConsecutiveEOSMarker = () => {
    setIgnoreConsecutiveEOSMarker(!ignoreConsecutiveEOSMarker);
  };

  function handleSimilarityThresholdSelection(event) {
    const value = event.target.value;
    setSimilarityThreshold(value);
  }

  // Database Operation
  async function fetchDataFromDB() {
    if (id) {
      async function fetchDataFromDatabase() {
        setConfirmView(false);
        const data = await fetchData(id);
        if (data) {
          setJSONData(data);
          setConfirmView(true);
        }
      }
      fetchDataFromDatabase();
    }
  }

  function handleGenerateReport() {
    setReportEndOfSentenceMarkerFirst(endOfSentenceMarkerFirst);
    setReportEndOfSentenceMarkerSecond(endOfSentenceMarkerSecond);
    setReportAlgorithmParamNgramSize(algorithmParamNgramSize);
    setReportSimilarityThreshold(similarityThreshold);
    setReportEndOfWordMarker(similarityThreshold);
    setReportIgnoreConsecutiveEOSMarker(ignoreConsecutiveEOSMarker);

    fetchDataFromDB();

    if (!id) {
      if (JSONData.content == null) {
        notifyError("No content set");
      }
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setIsConfirmed(true);
    }, 2000);
    setConfirmView(true);
  }

  // toast error function
  const notifyError = (message) =>
    toast(<p style={{ fontSize: 16 }}>{message}</p>, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: "error",
    });

  return (
    <MainLayout>
      {/* Section to upload encrypted zipped Data */}
      {/* Password Field */}
      {confirmView ? (
        <>
          {" "}
          <p align="center">
            <Button onClick={handleGenerateReport}>Generate Report</Button>
          </p>
          <p align="center">
            <a
              href="##"
              onClick={() => {
                toggleShowOptions((prev) => !prev);
              }}
              className="underline cursor-pointer dark:text-white"
            >
              {showOptions ? "Less Options" : "More Options"}
            </a>
          </p>
        </>
      ) : (
        <></>
      )}
      {showUploadButton && (
        <RenderUploadJSONZipButton returnData={loadUploadedJSONData} />
      )}

      <div className={!showOptions ? "hidden" : ""}>
        <div className="mt-4 w-full flex justify-center">
          <b>Report generation parameters: &nbsp; </b>
          N-gram size &nbsp;
          <select
            value={algorithmParamNgramSize}
            onChange={handleAlgorithmParamNgramSizeSelection}
          >
            {ngramOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          &nbsp; Similarity threshold &nbsp;
          <select
            value={similarityThreshold}
            onChange={handleSimilarityThresholdSelection}
          >
            {similarityThresholdOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <form className=" mt-4 w-full flex justify-center">
          <b>Text parsing character/s: &nbsp;</b>
          <label>
            Word separator &nbsp;
            <InputNumber
              type="text"
              value={endOfWordMarker}
              size="3"
              onChange={(e) => setEndOfWordMarker(e.target.value)}
            />
          </label>{" "}
          &nbsp;
          <label>
            Sentence separator (1<sup>st</sup>) &nbsp;
            <InputNumber
              type="text"
              size="3"
              value={endOfSentenceMarkerFirst}
              onChange={(e) => setEndOfSentenceMarkerFirst(e.target.value)}
            />
          </label>{" "}
          &nbsp;
          <label>
            Sentence separator (2<sup>nd</sup>) &nbsp;
            <InputNumber
              type="text"
              size="3"
              value={endOfSentenceMarkerSecond}
              onChange={(e) => setEndOfSentenceMarkerSecond(e.target.value)}
            />
          </label>
          &nbsp;
          <label>
            Collapse Reccuring EOS:
            <InputNumber
              type="checkbox"
              checked={ignoreConsecutiveEOSMarker}
              onChange={handleIgnoreConsecutiveEOSMarker}
            />
          </label>
        </form>
      </div>

      {loading ? <p align="center">Loading report...</p> : <></>}
      <hr />
      {isConfirmed && JSONData.content && (
        <>
          <div style={{ height: "50vh", overflow: "auto" }}>
            <RenderReportMoncao
              content={JSONData.content}
              question={JSONData.question}
              trimmedCount={reportAlgorithmParamNgramSize}
              endOfWordMarker={reportEndOfWordMarker}
              endOfSentenceMarkerFirst={reportEndOfSentenceMarkerFirst}
              endOfSentenceMarkerSecond={reportEndOfSentenceMarkerSecond}
              ignoreConsecutiveEOSMarker={reportIgnoreConsecutiveEOSMarker}
            />
          </div>
          <RenderReport
            content={JSONData.content}
            question={JSONData.question}
            trimmedCount={reportAlgorithmParamNgramSize}
            endOfWordMarker={reportEndOfWordMarker}
            endOfSentenceMarkerFirst={reportEndOfSentenceMarkerFirst}
            endOfSentenceMarkerSecond={reportEndOfSentenceMarkerSecond}
            ignoreConsecutiveEOSMarker={reportIgnoreConsecutiveEOSMarker}
          />
          {id ? (
            <></>
          ) : (
            <>
              {isZIPFile && (
                <FromHeaderFields
                  handleChildAuthorChange={handleChildAuthorChange}
                  handleChildTitleChange={handleChildTitleChange}
                  handleChildPasswordChange={handleChildPasswordChange}
                />
              )}
            </>
          )}
        </>
      )}
      <div className="flex gap-4 w-full justify-center flex-wrap items-center">
        {author && title && password ? (
          <SaveToDatabaseOption
            item={JSONData}
            encrypt={true}
            targetPage="codereport"
          />
        ) : (
          <>
            <Button onClick={handleClickViewExplanation}>Save</Button>
          </>
        )}
        {JSONData.content && isConfirmed && (
          <RenderGenerateJSONzipButton item={JSONData} />
        )}
      </div>
      <ToastContainer />
    </MainLayout>
  );
}
