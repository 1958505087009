// used react-hook-form to create the forms
import { useForm } from "react-hook-form";
import { useState } from "react";
import Input from "../input";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function DatePicker() {
  const [startDate, setStartDate] = useState(new Date());
  const removeTime = function (dateA, dateB) {
    return (
      dateA.getFullYear() === dateB.getFullYear() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getDate() === dateB.getDate()
    );
  };
  return (
    <div>
      <ReactDatePicker
        showIcon
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        className="bg-secondary dark:bg-primary dark:text-white"
        calendarClassName="bg-secondary"
        dayClassName={(date) => {
          return removeTime(startDate, date) ? "bg-primary" : "";
        }}
      />
    </div>
  );
}
export default function FromHeaderFields({
  handleChildAuthorChange,
  handleChildTitleChange,
  handleChildPasswordChange,
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  if (watch("author")) {
    handleChildAuthorChange(watch("author"));
  }
  if (watch("title")) {
    handleChildTitleChange(watch("title"));
  }
  if (watch("password")) {
    handleChildPasswordChange(watch("password"));
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full justify-center my-4 gap-2 items-center flex-wrap"
      >
        <label>Author</label>
        <input
          className="bg-secondary dark:bg-primary"
          {...register("author", { required: true })}
        />
        <br />
        <label>Title</label>
        <input
          className="bg-secondary dark:bg-primary"
          {...register("title", { required: true })}
        />
        <br />
        <label>Password</label>
        <input
          className="bg-secondary dark:bg-primary"
          {...register("password", { required: true })}
        />
        <br />
        <label>Date</label>
        <DatePicker />
        {/* <input className="bg-secondary dark:bg-primary" type="submit" /> */}
      </form>
    </>
  );
}
