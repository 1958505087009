import { useState } from "react";
import JSZip from "jszip";
import Crypto from "../../utils/Crypto";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function RenderUploadJSONZipButton({ returnData }) {
  const [key, setKey] = useState(0);

  function handleZipUpload(event) {
    const file = event.target.files[0];
    if (!file) {
      toast.error("No file selected.");
      return;
    }

    const reader = new FileReader();

    reader.onerror = (event) => {
      toast.error("Error reading file.");
      setKey((prevKey) => prevKey + 1); // Rerender the input button to clear the uploaded file name
    };

    reader.onload = async (event) => {
      try {
        const data = event.target.result;

        const zip = await JSZip.loadAsync(data);

        const jsonFile = zip.file("data.json");
        console.log(jsonFile);

        if (!jsonFile) {
          toast.error("No data.json file found in zip archive.");
          setKey((prevKey) => prevKey + 1);
          return;
        }

        const textData = await jsonFile.async("text");
        console.log(textData);

        toast.success("JSON file loaded successfully.");

        let jsonData;

        try {
          jsonData = JSON.parse(textData);
        } catch (error) {
          toast.error("Error parsing JSON.");
          setKey((prevKey) => prevKey + 1);
          return;
        }

        let decryptedContent;

        try {
          // decryptedContent = Crypto(jsonData.encryptedData, false);
          decryptedContent = jsonData;
          console.log(decryptedContent);
        } catch (error) {
          toast.error("Error decrypting content.");
          setKey((prevKey) => prevKey + 1); // Rerender the input button to clear the uploaded file name
          return;
        }

        returnData(decryptedContent); // Pass the JSON data to the parent component

        toast.success("Content decrypted successfully.");

        // localStorage.setItem("Writing_data", JSON.stringify(decryptedContent))
      } catch (error) {
        toast.error("Error loading zip archive.");
        setKey((prevKey) => prevKey + 1); // Rerender the input button to clear the uploaded file name
      }
    };

    reader.readAsBinaryString(file);
  }

  return (
    <p>
      <b>Data upload: &nbsp; </b>
      Encrypted zip file: &nbsp;
      <input key={key} type="file" accept=".zip" onChange={handleZipUpload} />
      <ToastContainer position="top-right" autoClose={3000} />
    </p>
  );
}
