import React, { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//import css
import "./assets/styles/styles.css";

//necessary pages
import RenderLandingPage from "./pages/landing-page";
import RenderWriting from "./pages/writing-page";
import RenderCodeReport from "./pages/code-report-page";
import RenderJudgeZero from "./pages/judge0-page";
import RenderNoteBook from "./pages/notebook-page";
import RenderOneCompiler from "./pages/one-compiler-page";
import RenderQuestionUpload from "./pages/question-upload-page";
import RenderWritingReport from "./pages/writing-report-page";
import SampleReports from "./pages/sample-reports-page";
import Articles from "./pages/articles-page";
import Media from "./pages/media-page";
import Manuscript from "./pages/manuscript-page";
import Story from "./pages/story-page";
import ErrorPage from "./pages/404-error";
import FaqPage from "./pages/faq";
import { languages } from "./data/language-options";

//contexts
import ThemeContext from "./context/theme-context";
import LanguageContext from "./context/language-context";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RenderLandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/writing",
    element: <RenderWriting />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/writing/:id",
    element: <RenderWriting />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/onecompiler",
    element: <RenderOneCompiler />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/onecompiler/:id",
    element: <RenderOneCompiler />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/faq",
    element: <FaqPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/judge0",
    element: <RenderJudgeZero />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/notebook",
    element: <RenderNoteBook />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/samplereports",
    element: <SampleReports />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/articles",
    element: <Articles />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/media",
    element: <Media />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/manuscript",
    element: <Manuscript />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/story",
    element: <Story />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/uploadquestion",
    element: <RenderQuestionUpload />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/writingreport",
    element: <RenderWritingReport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/writingreport/:id",
    element: <RenderWritingReport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/codereport",
    element: <RenderCodeReport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/codereport/:id",
    element: <RenderCodeReport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  const lang = new URLSearchParams(window.location.search).get("lang");

  var defaultLanguage = "en";
  //detecting the browser language
  const detectedlanguage = window.navigator.language;
  const isLanguageSupported = languages.some(
    (language) => language.code === detectedlanguage
  );
  // setting the browser language to the detected language if it exists
  if (isLanguageSupported) {
    defaultLanguage = detectedlanguage;
  } else if (lang) {
    defaultLanguage = lang;
  }
  const [language, setLanguage] = useState(defaultLanguage);
  const [theme, setTheme] = useState("light");
  const languageValue = { language, setLanguage };
  const themeValue = { theme, setTheme };
  return (
    <>
      <LanguageContext.Provider value={languageValue}>
        <ThemeContext.Provider value={themeValue}>
          <div className={`App-container ${theme}`}>
            <RouterProvider router={router} />
          </div>
        </ThemeContext.Provider>
      </LanguageContext.Provider>
    </>
  );
}

export default App;
